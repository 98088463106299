import store from '../../store';
import { isFeatureEnabled } from '../../shared/utils/CatalizrUtils';
import { isTemplateJourney } from './JourneyUtils';

import {
  OPERATION_TYPE_CREATE,
  OPERATION_TYPE_CAPITAL_INCREASE,
  OPERATION_TYPE_REPURCHASE,
  OPERATION_TYPE_DISPOSAL,
  OPERATION_TYPE_BOND,
  OPERATION_TYPE_PRIVATE_EQUITY,
  OPERATION_TYPE_KIND_CONTRIBUTION,
} from './CatalizrConstants';

export const create = () => checkOperationType(OPERATION_TYPE_CREATE);
export const disposal = () => checkOperationType(OPERATION_TYPE_DISPOSAL);
export const increase = () => {
  return checkOperationType(OPERATION_TYPE_CAPITAL_INCREASE);
};
export const repurchase = () => checkOperationType(OPERATION_TYPE_REPURCHASE);
export const checkDisplayRealOrEstimateDate = () =>
  !checkOperationType(OPERATION_TYPE_DISPOSAL) && isFeatureEnabled('display.investment_date');
export const bond = () => checkOperationType(OPERATION_TYPE_BOND);
export const privateEquity = () => checkOperationType(OPERATION_TYPE_PRIVATE_EQUITY);
export const kindContribution = () => checkOperationType(OPERATION_TYPE_KIND_CONTRIBUTION);
export const templateCreate = () => checkTemplateOperationType(OPERATION_TYPE_CREATE);
export const templateDisposal = () => checkTemplateOperationType(OPERATION_TYPE_DISPOSAL);
export const templateIncrease = () => checkTemplateOperationType(OPERATION_TYPE_CAPITAL_INCREASE);
export const templateRepurchase = () => checkTemplateOperationType(OPERATION_TYPE_REPURCHASE);
export const templateKindContribution = () =>
  checkTemplateOperationType(OPERATION_TYPE_KIND_CONTRIBUTION);
export const templateBond = () => checkTemplateOperationType(OPERATION_TYPE_BOND);
export const templatePrivateEquity = () =>
  checkTemplateOperationType(OPERATION_TYPE_PRIVATE_EQUITY);
export const isPortfolioMultiple = () => checkIsPortfolioMultiple();

export const getOperationType = () => {
  const template = store.getState().common.template;
  const investment = store.getState().investment;

  let operationType = isTemplateJourney()
    ? template.operation_type.label
    : investment.currentInvestmentType;
  return operationType;
};

export const getOperationLabel = operationTypeId => OPERATION_TYPES_MAPPING.get(operationTypeId);

export const getInvestmentDisposalMode = () => {
  return store.getState().investment.investmentDisposalMode;
};

// ----------------------------------------------------------------------------------------------
// PRIVATE FUNCTIONS
// ----------------------------------------------------------------------------------------------

/**
 * Checks if the given operation type is the current operation being processed.
 * @param {string} operationType the type of operation to check (ex: Gré à gré, Augmentation de capital, etc.)
 * @returns {boolean}
 */
export const checkOperationType = operationType => {
  return store.getState().investment.currentInvestmentType === operationType;
};

const checkTemplateOperationType = operationType => {
  const template = store.getState().common.template;
  return template && template.operation_type && template.operation_type.label === operationType;
};

const checkIsPortfolioMultiple = () => {
  return !!store.getState().investment.portfolio_type_multiple;
};

// Mapping between the ID of an operationType and its label
const OPERATION_TYPES_MAPPING = new Map([
  [1, OPERATION_TYPE_CREATE],
  [2, OPERATION_TYPE_CAPITAL_INCREASE],
  [3, OPERATION_TYPE_DISPOSAL],
  [4, OPERATION_TYPE_REPURCHASE],
  [5, OPERATION_TYPE_BOND],
  [6, OPERATION_TYPE_KIND_CONTRIBUTION],
  [7, OPERATION_TYPE_PRIVATE_EQUITY],
]);
